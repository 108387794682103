<template>
    <v-col>

        <s-crud add edit height="auto" :filter="filter" remove @doubleClick="dobleClick" title="Áreas" excel @save=save($event) :config="config">
            <template v-slot:filter>
                <v-col>
                    <v-row justify="center">
                        <v-col lg="3"><s-select-definition clearable v-model="filter.TypeArea" :def="1119" label="Tipo de Área"> </s-select-definition></v-col>
                    </v-row>
                </v-col>
            </template>
            <template scope="props">
                <v-col>
                    <v-row justify="center">
                        <v-col lg="4" class="s-col-form">
                            <s-text v-model="props.item.AreName" label="Nombre de área"> </s-text>
                        </v-col>
                        <v-col lg="4" class="s-col-form">
                            <s-text v-model="props.item.AreAbbreviation" label="Abreviación"> </s-text>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col lg="1" class="s-col-form"><s-text label="Siglas" max="4" v-model="props.item.AreAcronym"></s-text></v-col>
                        <v-col class="s-col-form" lg="3">
                            <s-select-definition v-model="props.item.TypeArea" label="Tipo de área" :def="1119"> </s-select-definition>
                        </v-col>
                        <v-col lg="4" class="s-col-form">
                            <s-select-area clearable
                                           :typeArea="props.item.TypeArea == 1 ? 10 : props.item.TypeArea - 1"
                                           :text="props.item.AreParentName"
                                           v-model="props.item.AreParentID"
                                           label="Área Superior">
                            </s-select-area>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col lg="4" class="s-col-form">
                            <s-select-position clearable v-model="props.item.PstBossID" label="Jefatura" :text="props.item.BossName"> </s-select-position>
                        </v-col>
                        <v-col lg="4" class="s-col-form">
                            <s-select-position clearable :text="props.item.ManagerName" v-model="props.item.PstManagerID" label="Gerencia"> </s-select-position>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </s-crud>
    </v-col>
</template>

<script>
import _sArea from "../../services/General/AreaService.js";
export default {
  name: "RequirementService",
  components: {},
  props: {},

  data: () => ({
    filter: { TypeArea: 0, AreID: 0 },
    config: {
      model: {
        AreID: "ID",
        PstManagerID: "int",
      },
      service: _sArea,
      headers: [
        { text: "ID", value: "AreID", sortable: false, width: 10 },
        { text: "Área", value: "AreName", sortable: false },
        { text: "Abreviación", value: "AreAbbreviation", sortable: false },
        { text: "Tipo", value: "TypeAreName", sortable: false },
        { text: "Gerencia", value: "ManagerName", sortable: false },
        { text: "Jefatura", value: "BossName", sortable: false },
      ],
    },
  }),
  methods: {
    save(item) {
      if (item.AreName.lenght < 1) {
        this.$fun.alert("Complete Nombre de Área", "warning");
        return;
      }
      item.save();
    },
    dobleClick(item) {
      this.$emit("dblClick", item);
    },
  },
  created() {},
};
</script>
